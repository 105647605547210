import Header from '../components/Header';
import Footer from "../components/Footer";
import '../assets/style.css';
import Accordion from '../components/Accordion';

const dietaEchilibrata = [
    { id: 1, isTitle: false, text: "Fructe și legume: Încercați să umpleți jumătate din farfurie cu fructe și legume la fiecare masă. Acestea sunt bogate în vitamine, minerale și antioxidanți care susțin sănătatea inimii."},
    { id: 2, isTitle: false, text: "Cereale integrale: Alegeți cereale integrale precum orezul brun, ovăzul, quinoa și grâul integral în locul cerealelor rafinate. Cerealele integrale ajută la menținerea nivelului constant de zahăr din sânge și la reducerea riscului de boli de inimă."},
    { id: 3, isTitle: false, text: "Proteine slabe: Optați pentru proteine slabe, cum ar fi peștele, păsările de curte, fasolea și leguminoasele. Peștii, în special peștii grași precum somonul și macroul, sunt bogați în acizi grași omega-3, care sunt benefici pentru sănătatea inimii."},
    { id: 4, isTitle: false, text: "Grăsimi sănătoase: Încorporați grăsimi sănătoase din surse precum avocado, nuci, semințe și ulei de măsline. Limitați grăsimile saturate și trans găsite în alimentele procesate și prăjite."},
    { id: 5, isTitle: false, text: "Reduceți sodiul și zahărul: Minimizați aportul de sare și zahăr evitând alimentele procesate și alegând alimente proaspete, integrale. Excesul de sodiu poate crește tensiunea arterială, iar prea mult zahăr poate duce la creșterea în greutate și la diabet, ambele crescând riscul bolilor de inima."}
]

const activitateFizica = [
    { id: 1, isTitle: false, text: "​Exerciții fizice regulate: Faceți cel puțin 150 de minute de exerciții aerobice de intensitate moderată sau 75 de minute de exerciții de intensitate viguroasă pe săptămână. Activități precum mersul pe jos, ciclismul, înotul și dansul sunt excelente pentru sănătatea cardiovasculară."},
    { id: 2, isTitle: false, text: "Antrenament de forță: Includeți exerciții de antrenament de forță cel puțin două zile pe săptămână. Construirea mușchilor ajută la menținerea unei greutăți sănătoase și susține sănătatea generală a inimii."},
    { id: 3, isTitle: false, text: "Rămâneți activ pe tot parcursul zilei: Reduceți timpul sedentar făcând pauze pentru a sta în picioare, a merge sau a vă întinde, mai ales dacă aveți un loc de muncă de birou. Micile schimbări, cum ar fi urcarea scărilor în loc de lift, pot face o mare diferență."}
]

const greutateSanatoasa = [
    { id: 1, isTitle: false, text: "​Aport caloric echilibrat: Asigurați-vă că aportul caloric se potrivește cu nivelul de activitate. Supraalimentarea și un stil de viață sedentar pot duce la creșterea în greutate, crescând riscul de boli de inimă."},
    { id: 2, isTitle: false, text: "Controlul porțiilor: Fiți atenți la mărimea porțiilor, mai ales atunci când luați masa în oraș. Utilizarea farfuriilor și bolurilor mai mici poate ajuta la controlul dimensiunilor porțiilor acasă."}
  ]

const fumatul = [
    { id: 1, isTitle: false, text: "​Renunțați la fumat: Fumatul este unul dintre cei mai importanți factori de risc pentru bolile de inimă. Căutați sprijin de la profesioniști din domeniul sănătății, grupuri de sprijin sau programe de renunțare la fumat pentru a vă ajuta să înlăturați acestă dependență."},
    { id: 2, isTitle: false, text: "Limitați alcoolul: Dacă beți alcool, faceți-o cu moderație. Pentru bărbați, aceasta înseamnă până la două băuturi pe zi, iar pentru femei, până la o băutură pe zi. Consumul excesiv de alcool poate duce la hipertensiune arterială, insuficiență cardiacă și accident vascular cerebral."}
]

const stres = [
    { id: 1, isTitle: false, text: "​Practicați tehnici de relaxare: Încorporați tehnici de relaxare, cum ar fi respirația profundă, meditația și yoga în rutina zilnică pentru a ajuta la gestionarea nivelului de stres."},
    { id: 2, isTitle: false, text: "Rămâneți conectat: Mențineți legături sociale puternice cu familia, prietenii și comunitatea dvs. Sprijinul social poate ajuta la reducerea stresului și la îmbunătățirea bunăstării generale."},
    { id: 3, isTitle: false, text: "Hobby-uri sănătoase: Implicați-vă în activități care vă plac, cum ar fi cititul, grădinăritul sau cântatul la un instrument muzical. Acestea pot oferi un sentiment de împlinire și relaxare."},
]

const cointroaleRegulate = [
    { id: 1, isTitle: false, text: "​Screening-uri de rutină: Controalele regulate pot ajuta la detectarea semnelor timpurii ale bolilor de inimă sau alte probleme de sănătate. Monitorizați-vă tensiunea arterială, nivelul colesterolului și glicemia în mod regulat."},
    { id: 2, isTitle: false, text: "Urmați sfatul medicului: Dacă aveți afecțiuni de sănătate existente, urmați sfaturile furnizorului de asistență medicală și luați medicamente conform prescripției pentru a vă gestiona eficient sănătatea."},
]

const prioritizatiSomnul = [
    { id: 1, isTitle: false, text: "​Somn de calitate: Indicate sunt 7-9 ore de somn de calitate în fiecare noapte. Somnul slab poate avea un impact negativ asupra sănătății inimii și poate crește riscul de boli de inimă."},
    { id: 2, isTitle: false, text: "Igiena somnului: Mențineți un program de somn constant, creați o rutină relaxantă înainte de culcare și asigurați-vă că mediul de somn este confortabil și lipsit de distrageri."},
]

const dieteSiNutritie = [
    { id: 1, isTitle: true, text: "Concentrați-vă pe alimente integrale"},
    { id: 2, isTitle: false, text: "Fructe și legume: Încorporați o varietate de fructe și legume colorate în dieta dvs. Încercați să consumați cel puțin cinci porții pe zi. Aceste alimente sunt bogate în vitamine, minerale și fibre esențiale, fiind în același timp sărace în calorii."},
    { id: 3, isTitle: false, text: "Cereale integrale: Alegeți cereale integrale în locul cerealelor rafinate. Opțiuni precum orezul brun, quinoa, pâinea integrală și fulgii de ovăz sunt bogate în fibre, care ajută la reducerea nivelului de colesterol și susține sănătatea inimii."},
    { id: 4, isTitle: true, text: "Includeți grăsimi sănătoase"},
    { id: 5, isTitle: false, text: "Grăsimi nesaturate: Încorporați surse de grăsimi nesaturate, cum ar fi avocado, nuci, semințe și ulei de măsline. Aceste grăsimi ajută la reducerea nivelului de colesterol rău (LDL) și la creșterea nivelului de colesterol bun (HDL)."},
    { id: 6, isTitle: false, text: "Acizi grași omega-3: Mănâncă pește gras precum somonul, macroul, sardinele și semințele de in. Acizii grași omega-3 reduc inflamația și scad riscul de boli de inimă."},
    { id: 7, isTitle: true, text: "Alege proteine slabe"},
    { id: 8, isTitle: false, text: "Pește și păsări de curte: Optați pentru pește și păsări fără piele ca principale surse de proteine animale. Peștele, în special peștele gras, este bogat în acizi grași omega-3 sănătoși pentru inimă."},
    { id: 9, isTitle: false, text: "Proteine pe bază de plante: Încorporați proteine pe bază de plante, cum ar fi fasole, linte și tofu. Acestea sunt sărace în grăsimi saturate și bogate în fibre, contribuind la o inimă sănătoasă."},
    { id: 10, isTitle: true, text: "Limitați grăsimile nesănătoase"},
    { id: 11, isTitle: false, text: "Grăsimi saturate și trans: Reduceți aportul de grăsimi saturate găsite în carnea roșie, produsele lactate integrale și alimentele prăjite. Evitați grăsimile trans găsite în multe produse procesate și de patiserie. Aceste grăsimi pot crește nivelul colesterolului rau și pot crește riscul de boli de inimă"},
    { id: 12, isTitle: true, text: "Reduceți aportul de sodiu"},
    { id: 13, isTitle: false, text: "Citiți etichetele: Acordați atenție etichetelor alimentelor și alegeți produse cu conținut mai scăzut de sodiu. Excesul de sodiu poate duce la hipertensiune arterială, un factor de risc major pentru bolile de inimă."},
    { id: 14, isTitle: false, text: "Gătește acasă: Pregătește mesele acasă folosind ingrediente proaspete pentru a controla cantitatea de sare din dieta ta. Folosiți ierburi, condimente și sucuri de citrice pentru a vă aromatiza mâncarea în loc de sare."},
    { id: 15, isTitle: true, text: "Rămâi hidratat"},
    { id: 16, isTitle: false, text: "Apă: Bea multă apă pe tot parcursul zilei pentru a rămâne hidratat. Hidratarea adecvată susține sănătatea generală și poate ajuta la gestionarea greutății prin reducerea probabilității de a mânca în exces."},
    { id: 17, isTitle: false, text: "Limitați băuturile dulci: Evitați băuturile dulci precum sucurile, băuturile energizante și ceaiurile îndulcite. Aceste băuturi contribuie la creșterea în greutate și pot crește riscul de boli de inimă."},
    { id: 18, isTitle: true, text: "Educați-vă"},
    { id: 19, isTitle: false, text: "Rămâneți informat: Fiți la curent cu sursele de încredere de informații nutriționale. Înțelegerea beneficiilor diferitelor alimente vă poate ajuta să faceți alegeri mai sănătoase."},
    { id: 20, isTitle: false, text: "Solicitați sfaturi profesionale: Consultați un dietetician sau un nutriționist pentru sfaturi dietetice personalizate și îndrumări adaptate nevoilor dumneavoastră specifice de sănătate."},
]

const exercitiiFizice = [
    { id: 1, isTitle: true, text: "Exerciții cardiovasculare"},
    { id: 2, isTitle: false, text: "Importanță: Exercițiile cardiovasculare, cunoscute și sub numele de exerciții aerobice, sunt esențiale pentru îmbunătățirea sănătății inimii. Acestea vă cresc ritmul cardiac, îmbunătățesc circulația sângelui și îmbunătățesc eficiența sistemului cardiovascular."},
    { id: 3, isTitle: false, text: "Exemple: includeți activități precum mersul pe jos, alergarea, ciclismul, înotul și dansul. Faceți cel puțin 150 de minute de activitate aerobică de intensitate moderată sau 75 de minute de intensitate viguroasă pe săptămână."},
    { id: 4, isTitle: true, text: "Antrenament de forță"},
    { id: 5, isTitle: false, text: "Importanță: Antrenamentul de forță ajută la construirea masei musculare, la întărirea oaselor și la stimularea metabolismului. De asemenea, ajută la îmbunătățirea sănătății inimii prin reducerea grăsimii corporale și gestionarea greutății."},
    { id: 6, isTitle: false, text: "Exemple: Încorporați exerciții precum ridicarea de greutăți, exerciții cu bandă de rezistență, exerciții cu greutatea corporală (flotări, genuflexiuni, fandări) și utilizarea aparatelor de greutăți la sală. Programați cel puțin două zile de antrenament de forță pe săptămână."},
    { id: 7, isTitle: true, text: "Flexibilitate și echilibru"},
    { id: 8, isTitle: false, text: "Importanță: Exercițiile de flexibilitate și echilibru vă îmbunătățesc funcția fizică generală, reduc riscul de accidentări și îmbunătățesc sănătatea articulațiilor. Aceste exerciții sunt deosebit de benefice pentru adulții în vârstă."},
    { id: 9, isTitle: false, text: "Exemple: Practicați activități precum yoga, Pilates, rutine de întindere și tai chi. Încorporați exerciții de flexibilitate și echilibru în rutina dvs. de cel puțin două până la trei ori pe săptămână."},
    { id: 10, isTitle: true, text: "Activitate fizică zilnică"},
    { id: 11, isTitle: false, text: "Importanță: Menținerea activității pe tot parcursul zilei este crucială pentru menținerea sănătății generale și prevenirea efectelor negative ale șederii prelungite. Micile schimbări în obiceiurile zilnice pot avea un impact semnificativ asupra nivelului tău de fitness."},
    { id: 12, isTitle: false, text: "Exemple: Mergeți pe scări în loc de lift, mergeți pe jos sau cu bicicleta la serviciu, faceți treburi casnice și faceți pauze scurte de mers pe jos în timpul zilei de lucru. Încercați să vă mișcați la fiecare oră pentru a întrerupe perioadele lungi de ședere."},
    { id: 13, isTitle: true, text: "Stabilirea unor obiective realiste"},
    { id: 14, isTitle: false, text: "Obiective SMART: Stabiliți obiective specifice, măsurabile, realizabile, relevante și limitate în timp (SMART) pentru a rămâne motivat și pentru a vă urmări progresul. Împărțiți obiectivele mai mari în pași mai mici și ușor de gestionat."},
    { id: 15, isTitle: false, text: "Consecvență: Consecvența este cheia pentru atingerea obiectivelor de fitness pe termen lung. Găsește activități care îți plac și fă-le o parte obișnuită a rutinei tale."},
    { id: 16, isTitle: true, text: "Rămâneți motivat"},
    { id: 17, isTitle: false, text: "Găsiți un partener de antrenament: Exercițiile fizice cu un prieten sau participarea la o clasă de fitness de grup vă pot face antrenamentele mai plăcute și vă pot responsabiliza."},
    { id: 18, isTitle: false, text: "Urmăriți-vă progresul: Utilizați aplicații de fitness, jurnale sau dispozitive portabile pentru a vă urmări progresul și a vă sărbători realizările."},
    { id: 19, isTitle: false, text: "Amestecă-l: Variați-vă antrenamentele pentru a menține lucrurile interesante și pentru a vă provoca corpul în moduri noi."},
    { id: 20, isTitle: true, text: "Siguranță și precauții"},
    { id: 21, isTitle: false, text: "Încălzire și răcire: Începeți întotdeauna antrenamentul cu o încălzire pentru a vă pregăti corpul și a reduce riscul de accidentare. Încheiați cu o răcire pentru a vă reduce treptat ritmul cardiac și pentru a promova recuperarea."},
    { id: 22, isTitle: false, text: "Ascultă-ți corpul: Fii atent la semnalele corpului tău. Dacă aveți dureri, amețeli sau dificultăți de respirație, opriți exercițiile fizice și solicitați sfatul medicului dacă este necesar."},
    { id: 23, isTitle: false, text: "Hidratare și nutriție: Rămâneți hidratat bând apă înainte, în timpul și după exerciții fizice. Alimentează-ți corpul cu mese și gustări echilibrate pentru a-ți susține obiectivele de fitness."},
    { id: 24, isTitle: true, text: "Îndrumare profesională"},
    { id: 25, isTitle: false, text: "Consultați un antrenor: Dacă incepeți acum exerciții fizice sau aveți obiective specifice de fitness, luați în considerare consultarea unui antrenor personal certificat. Ei pot crea un plan de antrenament personalizat și se pot asigura că utilizați forma adecvată."},
    { id: 26, isTitle: false, text: "Aviz medical: Dacă aveți afecțiuni cronice sau sunteți în curs de diagnosticare, consultați medicul înainte de a începe un nou program de exerciții fizice"},
    { id: 27, isTitle: true, text: "Bucurați-vă de călătorie"},
    { id: 28, isTitle: false, text: "Distrează-te: Găsește activități fizice care îți plac și care te fac să te simți bine. Exercițiile fizice ar trebui să fie o parte pozitivă și plăcută a stilului tău de viață."},
    { id: 29, isTitle: false, text: "Sărbătorește reușitele: Recunoaște-ți și sărbătorește-ți reușitele de fitness, indiferent cât de mici. Fiecare pas înainte este un pas spre o sănătate mai bună."},
    { id: 30, isTitle: false, text: "Prin încorporarea exercițiilor fizice regulate și a activității fizice în rutina zilnică, vă puteți îmbunătăți semnificativ sănătatea inimii și bunăstarea generală. Amintiți-vă, consecvența și plăcerea sunt cheia pentru a menține un angajament pe tot parcursul vieții față de fitness. Începeți astăzi și faceți pași proactivi către o viață mai sănătoasă și mai activă!"},
]

const sanatateaMintala = [
    { id: 1, isTitle: true, text: "Înțelegerea legăturii dintre sănătatea mintală și cea a inimii"},
    { id: 2, isTitle: false, text: "Impactul stresului: Stresul cronic poate duce la hipertensiune arterială, inflamație și alte probleme cardiovasculare. Gestionarea stresului este crucială pentru menținerea sănătății mentale și a inimii."},
    { id: 3, isTitle: false, text: "Tulburări de sănătate mintală: Afecțiuni precum depresia și anxietatea pot crește riscul de boli de inimă. În schimb, viața cu boli de inimă poate afecta sănătatea mintală, creând un ciclu care trebuie abordat holistic."},
    { id: 4, isTitle: true, text: "Tehnici de gestionare a stresului"},
    { id: 5, isTitle: false, text: "Mindfulness și meditație: Practicarea mindfulness și meditația poate ajuta la reducerea nivelului de stres, la îmbunătățirea concentrării și la promovarea unui sentiment de calm. Petreceți câteva minute în fiecare zi în reflecție liniștită sau meditație ghidată."},
    { id: 6, isTitle: false, text: "Exerciții de respirație profundă: Tehnicile de respirație profundă, cum ar fi respirația diafragmatică, pot ajuta la reducerea stresului și a anxietății. Exersați inspirația profundă pe nas, ținând aerul câteva secunde și expirând încet pe gură."},
    { id: 7, isTitle: false, text: "Relaxare musculară progresivă: Această tehnică implică tensionarea și apoi relaxarea diferitelor grupe musculare pentru a elibera tensiunea fizică și a promova relaxarea."},
    { id: 8, isTitle: true, text: "Conexiuni sociale"},
    { id: 9, isTitle: false, text: "Sistem de sprijin: Înconjoară-te de prieteni și membri ai familiei care te susțin. Conexiunile sociale pot oferi sprijin emoțional, pot reduce sentimentele de izolare și pot îmbunătăți bunăstarea generală."},
    { id: 10, isTitle: false, text: "Implicarea comunității: Alăturați-vă cluburilor, grupurilor sau organizațiilor de voluntariat pentru a cunoaște oameni noi și a construi un sentiment de comunitate. Implicarea în activități sociale vă poate spori sentimentul de apartenență și scop."},
    { id: 11, isTitle: true, text: "Activitate fizică regulată"},
    { id: 12, isTitle: false, text: "Exerciții fizice pentru sănătatea mintală: Activitatea fizică regulată eliberează endorfine, care sunt ridicătoare naturale ale dispoziției. Urmăriți cel puțin 30 de minute de exerciții moderate în majoritatea zilelor săptămânii pentru a îmbunătăți atât sănătatea mentală, cât și cea fizică."},
    { id: 13, isTitle: false, text: "Exerciții minte-corp: Activități precum yoga și tai chi combină mișcarea fizică cu concentrarea mentală, ajutând la reducerea stresului și la promovarea relaxării."},
    { id: 14, isTitle: true, text: "Obiceiuri sănătoase de somn"},
    { id: 15, isTitle: false, text: "Program de somn constant: Mențineți un program de somn regulat mergând la culcare și trezindu-vă la aceeași oră în fiecare zi. Acest lucru ajută la reglarea ceasului intern al corpului și îmbunătățește calitatea somnului."},
    { id: 16, isTitle: false, text: "Mediu de somn: Creați un mediu de somn odihnitor, păstrându-vă dormitorul răcoros, întunecat și liniștit. Evitați ecranele și activitățile stimulante înainte de culcare pentru a promova relaxarea."},
    { id: 17, isTitle: true, text: "Dietă echilibrată"},
    { id: 18, isTitle: false, text: "Alimente bogate în nutrienți: O dietă echilibrată, bogată în fructe, legume, cereale integrale, proteine slabe și grăsimi sănătoase poate avea un impact pozitiv asupra stării de spirit și a nivelului de energie. Acizii grași Omega-3, care se găsesc în pește și semințele de in, sunt deosebit de benefici pentru sănătatea creierului."},
    { id: 19, isTitle: false, text: "Hidratare: Rămâneți hidratat bând multă apă pe tot parcursul zilei. Deshidratarea vă poate afecta starea de spirit și funcția cognitivă."},
    { id: 20, isTitle: true, text: "Ajutor profesional"},
    { id: 21, isTitle: false, text: "Terapie și consiliere: Dacă vă confruntați cu probleme de sănătate mintală, luați în considerare să solicitați ajutor de la un profesionist în sănătate mintală. Terapia și consilierea pot oferi sprijin, strategii de adaptare și un spațiu sigur pentru a vorbi despre sentimentele tale."},
    { id: 22, isTitle: false, text: "Medicamente: În unele cazuri, medicamentele prescrise de un furnizor de asistență medicală pot fi necesare pentru a gestiona afecțiunile de sănătate mintală. Este important să urmați sfaturile medicului și să luați medicamentele așa cum sunt prescrise."},
    { id: 23, isTitle: true, text: "Activități conștiente"},
    { id: 24, isTitle: false, text: "Hobby-uri și interese: Implicați-vă în activități care vă plac și care vă oferă un sentiment de împlinire și bucurie. Fie că este vorba de pictură, grădinărit, citit sau cântat la un instrument, hobby-urile pot fi o modalitate excelentă de a vă relaxa și de a reduce stresul."},
    { id: 25, isTitle: false, text: "Timp în natură: Petrecerea timpului în aer liber poate avea un efect calmant și vă poate îmbunătăți starea de spirit. Mergeți la o plimbare în parc, faceți drumeții în pădure sau pur și simplu stați afară și bucurați-vă de aerul curat."},
]

const aderentaLaMedicatie = [
    { id: 1, isTitle: true, text: "Înțelegerea importanței aderenței la medicație"},
    { id: 2, isTitle: false, text: "Management consecvent: Administrarea medicamentelor așa cum este prescrisă este crucială pentru gestionarea afecțiunilor cardiace, controlul tensiunii arteriale, scăderea colesterolului și prevenirea complicațiilor. Aderența asigură eficacitatea planului de tratament."},
    { id: 3, isTitle: false, text: "Reducerea riscurilor: Aderența adecvată la medicație reduce riscul de atacuri de cord, accidente vasculare cerebrale și alte evenimente cardiovasculare grave. Sărirea peste doze sau oprirea medicației poate duce la agravarea simptomelor și a rezultatelor ."},
    { id: 4, isTitle: true, text: "Sfaturi pentru a rămâne pe drumul cel bun cu medicamentele"},
    { id: 5, isTitle: false, text: "Creați o rutină: Încorporați administrarea medicamentelor în rutina zilnică. Aliniați-l cu alte obiceiuri zilnice, cum ar fi spălatul pe dinți sau mesele, pentru a vă aminti mai ușor."},
    { id: 6, isTitle: false, text: "Utilizați organizatoare de pastile: Organizatoarele sau dozatoarele de pastile vă pot ajuta să țineți evidența medicamentelor, asigurându-vă că luați doza potrivită la momentul potrivit."},
    { id: 7, isTitle: false, text: "Setează mementouri: folosește alarme, notificări telefonice sau aplicații de memento pentru medicamente pentru a te anunța când este timpul să iei medicamentele."},
    { id: 8, isTitle: false, text: "Păstrați un jurnal de medicamente: Păstrați un jurnal pentru a urmări aportul de medicamente. Notați ora și doza de fiecare dată când luați medicamentul."},
    { id: 9, isTitle: true, text: "Înțelegerea medicamentelor"},
    { id: 10, isTitle: false, text: "Cunoașteți-vă medicamentele: Aflați numele, dozele și scopurile medicamentelor dumneavoastră. Înțelegerea de ce aveți nevoie de fiecare medicament vă poate motiva să respectați regimul dumneavoastră."},
    { id: 11, isTitle: false, text: "Puneți întrebări: Dacă aveți întrebări sau nelămuriri cu privire la medicamentele dumneavoastră., nu ezitați să întrebați medicul sau farmacistul. Clarificați orice îndoială cu privire la efectele secundare, interacțiuni și ce trebuie să faceți dacă omiteți o doză."},
    { id: 12, isTitle: true, text: "Comunicarea cu furnizorii de servicii medicale"},
    { id: 13, isTitle: false, text: "Controale regulate: Programați întâlniri regulate cu medicul pentru a vă revizui medicamentele și a vă asigura că funcționează eficient. Discutați despre orice efecte secundare sau probleme pe care le puteți întâmpina."},
    { id: 14, isTitle: false, text: "Onestitatea este cheia: Fii sincer cu medicul cu privire la aderența la medicamente. Dacă ați uitat doze sau ați încetat să luați un medicament, anunțați-l pentru a vă putea ajusta planul de tratament dacă este necesar."},
    { id: 15, isTitle: true, text: "Gestionarea efectelor secundare"},
    { id: 16, isTitle: false, text: "Monitorizați efectele secundare: Acordați atenție oricăror efecte secundare și raportați-le medicului. Adesea vă pot ajusta doza sau poate schimba medicamentele pentru a reduce efectele nedorite."},
    { id: 17, isTitle: false, text: "Nu vă opriți brusc: Nu încetați niciodată să vă luați medicamentele fără a vă consulta medicul, chiar dacă aveți efecte secundare. El vă pot ghida cu privire la cel mai bun curs de acțiune."},
    { id: 18, isTitle: true, text: "Abordarea barierelor din calea aderenței"},
    { id: 19, isTitle: false, text: "Costul medicamentelor: Dacă costul medicamentelor este o barieră, discutați acest lucru cu medicul. El poate prescrie o versiune generică sau poate oferi informații despre programele de asistență pentru pacienți."},
    { id: 20, isTitle: false, text: "Programe complexe: Dacă vă este greu să gestionați un program complex de medicație, discutați cu medicul dumneavoastră despre simplificarea acestuia. Pot exista opțiuni pentru medicamente combinate sau formule cu acțiune mai lungă."},
    { id: 21, isTitle: false, text: "Uitarea: Dacă uitarea este o problemă, utilizați instrumente precum aplicații de reamintire , organizator de pastile sau solicitați ajutorul unui membru al familiei pentru a vă reaminti."},
    { id: 22, isTitle: true, text: "A rămâne informat"},
    { id: 23, isTitle: false, text: "Resurse educaționale: Utilizați resurse educaționale din surse de renume pentru a rămâne informat despre starea și medicamentele dumneavoastră. Cunoștințele vă permit să vă asumați un rol activ în managementul sănătății."},
    { id: 24, isTitle: false, text: "Grupuri de sprijin: Alăturați-vă grupurilor de sprijin pentru persoane cu afecțiuni de sănătate similare. Împărtășirea experiențelor și a sfaturilor poate oferi încurajare și sfaturi practice pentru aderența la medicamente."},
    { id: 25, isTitle: true, text: "Alege un stil de viață sănătos"},
    { id: 26, isTitle: false, text: "Dieta și exercițiile fizice: Completați regimul de medicamente cu o dietă sănătoasă pentru inimă și activitate fizică regulată. Aceste modificări ale stilului de viață pot spori eficacitatea medicamentelor."},
    { id: 27, isTitle: false, text: "Evitați obiceiurile dăunătoare: Evitați fumatul, consumul excesiv de alcool și consumul de droguri recreaționale, deoarece acestea pot interfera cu medicamentele și sănătatea generală a inimii."},
    { id: 28, isTitle: true, text: "Urmărirea rezervelor"},
    { id: 29, isTitle: false, text: "Monitorizați-vă aprovizionarea: Fiți cu ochii pe rezerva de medicamente și comandați din nou înainte de a se epuiza. Setați memento-uri pentru când este timpul să mergeți la medic pentru rețetă."},
    { id: 30, isTitle: true, text: "Căutarea sprijinului"},
    { id: 31, isTitle: false, text: "Familia și prietenii: Implicați familia și prietenii în rutina de medicamente. Ei pot oferi sprijin, memento-uri și încurajare pentru a vă ajuta să rămâneți pe drumul cel bun."},
    { id: 32, isTitle: false, text: "Ajutor profesional: Dacă vă luptați cu aderența la medicamente, solicitați ajutor de la profesioniști din domeniul sănătății, cum ar fi farmaciști, asistente medicale sau susținători ai pacienților. Ei pot oferi strategii și sprijin personalizat."},
    { id: 33, isTitle: false, text: "Acordând prioritate aderenței la medicamente, vă puteți gestiona eficient sănătatea inimii și puteți reduce riscul de complicații. Amintiți-vă că consecvența și comunicarea deschisă cu furnizorul de asistență medicală sunt esențiale pentru menținerea unui plan de tratament eficient. Preia controlul asupra sănătății tale astăzi, angajându-te într-un regim care îți susține inima și bunăstarea generală."},
]

const recuperareCardiaca = [
    { id: 1, isTitle: true, text: "Ce este recuperarea cardiacă?"},
    { id: 2, isTitle: false, text: "Program cuprinzător: Recuperarea cardiacă (reabilitare) este un program supravegheat medical conceput pentru a vă îmbunătăți sănătatea cardiovasculară după un atac de cord, o intervenție chirurgicală la inimă sau alte afecțiuni cardiace. Implică antrenament fizic, educație pentru o viață sănătoasă pentru inimă și consiliere pentru a reduce stresul și a îmbunătăți sănătatea mintală."},
    { id: 3, isTitle: false, text: "Abordare holistică: Programul abordează aspectele fizice, emoționale și sociale ale recuperării, oferind o abordare cuprinzătoare a sănătății inimii."},
    { id: 4, isTitle: true, text: "Beneficiile recuperării cardiace"},
    { id: 5, isTitle: false, text: "Îmbunătățirea sănătății inimii: Exercițiile fizice regulate și schimbările stilului de viață pot întări inima și îi pot îmbunătăți funcția, reducând riscul de viitoare probleme cardiace."},
    { id: 6, isTitle: false, text: "O calitate mai bună a vieții: Participanții experimentează adesea niveluri îmbunătățite de energie, simptome reduse și o calitate mai bună a vieții."},
    { id: 7, isTitle: false, text: "Sprijin emoțional: Grupurile de consiliere și sprijin ajută la gestionarea stresului, anxietății și depresiei, care sunt frecvente după un eveniment cardiac."},
    { id: 8, isTitle: false, text: "Risc redus: Reabilitarea cardiacă poate reduce semnificativ riscul de a avea probleme cardiace viitoare, reinternări în spital și mortalitate."},
    { id: 9, isTitle: true, text: "Componentele unui program de recuperare cardiacă"},
    { id: 10, isTitle: false, text: "Educație: Sesiunile educaționale oferă informații despre o viață sănătoasă pentru inimă, inclusiv dietă, exerciții fizice, gestionarea stresului și renunțarea la fumat. Învățarea despre factorii de risc și cum să-i gestionezi este o componentă cheie a programului."},
    { id: 11, isTitle: false, text: "Consiliere și sprijin: Sprijinul psihologic este oferit pentru a ajuta la gestionarea provocărilor de sănătate emoțională și mintală. Sesiunile de grup și consilierea individuală oferă un mediu de susținere pentru a împărtăși experiențe și strategii de adaptare."},
    { id: 12, isTitle: true, text: "Cine poate beneficia de recuperare cardiacă?"},
    { id: 13, isTitle: false, text: "Supraviețuitorii atacului de cord: Persoanele care se recuperează după un atac de cord pot beneficia de recuperare structurată pentru a-și recăpăta puterea și a îmbunătăți sănătatea inimii."},
    { id: 14, isTitle: false, text: "Pacienții postoperatorii: Cei care au suferit o intervenție chirurgicală la inimă, cum ar fi operația de bypass, înlocuirea valvei sau angioplastia, pot beneficia de un plan de recuperare supravegheat."},
    { id: 15, isTitle: false, text: "Afecțiuni cardiace cronice: Persoanele cu afecțiuni cardiace cronice, cum ar fi insuficiența cardiacă sau angina pectorală stabilă, își pot îmbunătăți calitatea vieții și pot gestiona simptomele prin recuperare cardiacă."},
]

const raspunsDeUrgenta = [
    { id: 1, isTitle: true, text: "Recunoașterea semnelor unui atac de cord"},
    { id: 2, isTitle: false, text: "Durere sau disconfort în piept: Cel mai frecvent simptom al unui atac de cord este durerea sau disconfortul în piept care se poate simți ca presiune, strângere, plenitudine sau durere. Poate dura mai mult de câteva minute sau poate dispărea și se poate întoarce."},
    { id: 3, isTitle: false, text: "Durerea sau disconfortul pot radia, de asemenea, către umeri, brațe, spate, gât, maxilar sau stomac."},
    { id: 4, isTitle: false, text: "Dificultăți de respirație: Acest lucru poate apărea cu sau fără disconfort în piept."},
    { id: 5, isTitle: false, text: "Alte simptome: Transpirația rece, greața, amețelile pot fi, de asemenea, semne ale unui atac de cord, în special la femei."},
    { id: 6, isTitle: true, text: "Recunoașterea semnelor unui accident vascular cerebral"},
    { id: 7, isTitle: false, text: "Fața căzută: O parte a feței poate cădea sau se poate simți amorțită. Cereți persoanei să zâmbească pentru a verifica asimetria."},
    { id: 8, isTitle: false, text: "Slăbiciunea brațului: Un braț poate fi slab sau amorțit. Cereți-i persoanei să ridice ambele brațe pentru a vedea dacă unul se deplasează în jos."},
    { id: 9, isTitle: false, text: "Dificultate de vorbire: Vorbirea poate fi neclară sau dificil de înțeles. Roagă persoana să repete o propoziție simplă."},
    { id: 10, isTitle: false, text: "Este timpul să apelați serviciile de urgență: Dacă observați oricare dintre aceste simptome, sunați imediat la serviciile de urgență. Timpul este esențial în tratarea unui accident vascular cerebral."},
    { id: 11, isTitle: true, text: "Administrarea RCP"},
    { id: 12, isTitle: false, text: "Verificați capacitatea de răspuns: atingeți persoana și strigați pentru a vedea dacă răspunde. Dacă nu răspund și nu respiră sau doar gâfâie, începeți RCP."},
    { id: 13, isTitle: false, text: "Compresii toracice: Așezați mâinile pe centrul pieptului și împingeți tare și repede la o rată de 100 până la 120 de compresii pe minut. Lăsați pieptul să se ridice complet între compresii."},
    { id: 14, isTitle: false, text: "Respirații de salvare: Dacă sunteți instruit, dați respirații de salvare după fiecare 30 de compresii. Înclinați capul persoanei înapoi, ridicați-i bărbia și respirați de două ori. Fiecare respirație ar trebui să facă pieptul să se ridice."},
    { id: 15, isTitle: false, text: "Continuați până la sosirea ajutorului: Continuați să efectuați RCP până când respondenții de urgență preiau controlul sau persoana începe să respire normal."},
    { id: 16, isTitle: true, text: "Prevenire și conștientizare"},
    { id: 17, isTitle: false, text: "Controale regulate: Încurajați controalele medicale regulate pentru a monitoriza sănătatea inimii și pentru a gestiona factorii de risc, cum ar fi hipertensiunea arterială, colesterolul și diabetul."},
    { id: 18, isTitle: false, text: "Stil de viață sănătos: Promovați un stil de viață sănătos pentru inimă, inclusiv o dietă echilibrată, exerciții fizice regulate, renunțarea la fumat și limitarea consumului de alcool."},
    { id: 19, isTitle: false, text: "Educație: Educați membrii familiei și colegii despre semnele și simptomele atacurilor de cord și ale accidentelor vasculare cerebrale și despre importanța de a acționa rapid în caz de urgență."},
]

const accordionItems = [
    {title: "Mănâncă o dietă echilibrată", content: dietaEchilibrata},
    {title: "Rămâi activ fizic", content: activitateFizica},
    {title: "Mențineți o greutate sănătoasă", content: greutateSanatoasa},
    {title: "Renunțați la fumat și limitați alcoolul", content: fumatul},
    {title: "Gestionați stresul", content: stres},
    {title: "Faceți controale regulate de sănătate", content: cointroaleRegulate},
    {title: "Prioritizați somnul", content: prioritizatiSomnul},
    {title: "Dieta și nutriția", content: dieteSiNutritie},
    {title: "Exerciții fizice și fitness: întărirea inimii și a corpului", content: exercitiiFizice},
    {title: "Sănătatea mintală: hrănirea minții și a inimii", content: sanatateaMintala},
    {title: "Aderența la medicație: asigurarea unui management eficient al sănătății inimii", content: aderentaLaMedicatie},
    {title: "Recuperarea cardiacă: Reconstruirea sănătății inimii", content: recuperareCardiaca},
    {title: "Răspuns de urgență: Acționăm rapid pentru a salva vieți", content: raspunsDeUrgenta},

]

const BoliCardiovascularePrevenire = () => {
 
    return(
        <div className="min-h-screen flex flex-col">
            <Header />
            <div className="flex-grow">
                <section id="accordionSection">
                    <div className="container mx-auto p-6">
                    <Accordion items={accordionItems} />
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
  };
  export default BoliCardiovascularePrevenire;