import '../assets/style.css'
import Header from '../components/Header'
import Footer from '../components/Footer';
import Presa from '../components/Presa';
import Campanii from '../components/Campanii';

const Home = () => {
    return (
      <div className="min-h-screen flex flex-col">
        <Header/>
          <div className="flex-grow px-6 pt-14 lg:px-8">
            <div className="sm:py-20 lg:py-20 xl:pb-0">
              <div className="text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl ">
                Împreună pentru sănătatea inimii
                </h1>
              </div>
            </div>
            <div className="sm:py-20 lg:py-20 xl:pb-0">
              <div className="text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-500 sm:text-5xl ">
                Campanii
                </h1>
              </div>
            </div>
            <Campanii/>
            <div className="sm:py-20 lg:py-20 xl:pb-0">
              <div className="text-center">
                <h1 className="text-3xl font-bold tracking-tight text-gray-500 sm:text-5xl ">
                Comunicate de presă
                </h1>
              </div>
            </div>
            <Presa/>
          </div>
        <Footer/>
      </div>
    );
  };
  
  export default Home;
  