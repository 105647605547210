import { useState } from 'react';

function Accordion({ items }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="w-full space-y-2">
      {items.map((item, index) => (
        <div key={index} className="border-b border-gray-200">
          <button
            className="w-full text-left pt-4 pb-4 flex justify-between items-center"
            onClick={() => toggleAccordion(index)}
          >
            <span className="text-lg font-bold text-gray-800">{item.title}</span>
            <span
              className={`transition-transform duration-200 ${
                openIndex === index ? 'rotate-180' : 'rotate-0'
              }`}
            >
              ▼
            </span>
          </button>
          <div
            className={`overflow-hidden transition-[max-height] duration-300 ${
              openIndex === index ? '' : 'max-h-0'
            }`}
          >
            {item.content.map((content) => (
                <div key={content.id} className={`mb-2 text-gray-500 ${content.isTitle ? "font-bold mt-6" : ''}`}>
                  {content.text}
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordion;
