import Header from '../components/Header';
import Footer from "../components/Footer";
import '../assets/style.css';
import { PhoneIcon } from '@heroicons/react/24/outline';

const InformatiiDeUrgenta = () => {
 
    return(
        <div className="min-h-screen flex flex-col">
            <Header/>
            <div className="flex-grow">
                <div className='flex justify-center items-center flex-col'>
                    <h2 className='mb-6 mt-6 text-4xl font-bold text-center'>Numărul de urgență pe teritoriul României</h2>
                    <span className='w-36 h-24 bg-red-600 flex rounded-xl justify-center text-white font-bold text-6xl items-center ring-1 ring-inset ring-red-600/10'>
                    112
                    </span> 
                </div>
                <div className='flex justify-center items-center flex-col pt-10 pb-10'>
                    <div className='flex flex-col lg:text-left lg:w-2/4 text-xl'>
                        <h2 className='text-2xl font-bold text-center lg:text-left'>
                            Informații de urgență
                        </h2>
                        <p className='p-5'>
                        Pentru Asociația Solidari pentru Sănătate, siguranța și bunăstarea dumneavoastră
                        reprezintă prioritățile noastre principale. În cazul unei urgențe de sănătate a inimii,
                        este esențial să acționați rapid și să solicitați asistență medicală imediată. Această
                        pagină oferă informații esențiale despre recunoașterea urgențelor cardiace, pașii de
                        urmat în caz de urgență și detaliile de contact pentru serviciile de urgență.
                        </p>

                        <h2 className='text-2xl font-bold pt-6 text-center lg:text-left'>
                            Pași de urmat în caz de urgență
                            Sunați imediat pentru ajutor:
                        </h2>
                        <p className='p-5'>
                        1. Formați numărul local de urgență ( 112 în România) sau mergeți la cea mai
                        apropiată cameră de gardă a spitalului.
                        Furnizați operatorului locația dumneavoastră și descrieți simptomele în mod clar și
                        calm.
                        </p>
                        <p className='p-5'>
                        2. Administrați primul ajutor:
                        Dacă persoana este inconștientă și nu respiră, începeți RCP (resuscitare
                        cardiopulmonară) dacă sunteți instruit.
                        Utilizați un defibrilator extern automat (AED), dacă este disponibil și dacă sunteți
                        instruit să faceți acest lucru.
                        </p>
                        <p className='p-5'>
                        3. Calmați și oferiți sprijin:
                        Păstrați persoana calmă și așezată sau întinsă.
                        Slăbește orice îmbrăcăminte strâmtă și asigură-te că au mult aer proaspăt.
                        </p>
                        <p className='p-5'>
                        4.Nu întârziați:
                        Timpul este critic în situații de urgență cardiacă. Nu ezitați să solicitați asistență
                        medicală imediată.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
  };
  export default InformatiiDeUrgenta;