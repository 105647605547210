import Header from '../components/Header';
import Footer from "../components/Footer";

const Doneaza = () => {

  return (
    <div className="min-h-screen flex flex-col">
      <Header/>
        <div className="flex-grow">
            <div className='flex mx-auto justify-center w-3/4 lg:w-1/2 flex-col '>
                <h2 className="font-bold text-3xl">Doneaza</h2>
                <p className='text-xl mt-6'>
                Noi credem cu tărie în puterea comunității și în impactul generozității colective. Sprijinul dumneavoastră
                ne motivează în misiunea de a crește gradul de conștientizare cu privire la sănătatea inimii, bolile
                asociate și de a sprijini pacienții și familiile lor în călătoria către o viață sănătoasă, chiar și în fața unei
                afecțiuni cardiace. Împreună, putem face o diferență reală în viața celor care au nevoie de ajutor.
                </p>
                <div className='flex justify-center items-center flex-col gap-10 mt-10 mb-10'>
                    <div className='w-auto'>
                        <a
                        href='230_OPANAF_15_2021_prima pagina-1.pdf'
                        download='230_OPANAF_15_2021_prima pagina-1.pdf'
                        className="rounded-md bg-red-500 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 inline-flex items-center"             
                        >
                            Descarca formularul 230 
                        </a>
                    </div>
                    <div className='w-auto'>
                        <a
                        href='CONTRACT-DE-SPONSORIZARE-in-lei.docx'
                        download='CONTRACT-DE-SPONSORIZARE-in-lei.docx'
                        className="rounded-md bg-red-500 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 inline-flex items-center"             
                        >
                            Descarca contract de sponsorizare 
                        </a>
                    </div>
                </div>
            </div>
        </div>
      <Footer/>
    </div>
  );
};

export default Doneaza;
  