import React from 'react';
import '../assets/style.css';

const resurse = [
  { name: "Despre noi", href: "/despre-noi"},
  { name: "Invata despre bolile cardiovasculare", href: "/bolile-cardiovasculare"},
  { name: "Preventie si ingrijire", href: "/bolile-cardiovasculare-prevenire-si-ingrijire"},
  { name: "Resurse", href: "/informatii-de-urgenta"},
]


function Footer() {

  return (
    <footer className="bg-white dark:bg-gray-900 bottom-0">
    <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
      <div className="md:flex md:justify-between">
        <div className="mb-6 md:mb-0">
            <img
              src="logo-sps-dark.png"
              className="h-16 me-3"
              alt="Solidari Pentru Sanatate"
            />
        </div>
        <div>
          <div className='text-right'>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
              Resurse utile
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              {resurse.map((item) => (
                  <li className="mb-4">
                  <a href={item.href} className="hover:underline">
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © Copyright 2024{" "}
          <a href="https://flowbite.com/" className="hover:underline">
            Company
          </a>
          . Made by  <img src="/Hiveflow_logo.png" alt="Hiveflow logo" className='h-4 inline'/> <a href="https://hiveflow.app" className="hover:underline" target="_blank">
            Hiveflow
          </a>
        </span>
        <div className="flex mt-4 sm:justify-center sm:mt-0">
          <a
            href="https://www.facebook.com/Solidari.cl"
            target="_blank"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img src="facebook.png" alt='facebook solidari pentru sanatate'/>
            <span className="sr-only">Facebook page</span>
          </a>
          <a
            href="https://www.linkedin.com/in/solidari-pentru-s%C4%83n%C4%83tate-3933bb314/"
            target="_blank"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            <img src="linkedin.png" alt='linkedin solidari pentru sanatate'/>
            <span className="sr-only">Facebook page</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
  
  );
}

export default Footer;
