import React from 'react';
import { ArrowTrendingUpIcon,TagIcon } from '@heroicons/react/24/outline';

const Aboutus = () =>{
  const steps = [

    {
      name: 'Susținem dezvoltarea și implementarea',
      description:
        'politicilor de sănătate a inimii care acordă prioritate prevenirii, detectării timpurii și gestionării eficiente a bolilor cardiovasculare.',
    },
    {
      name: 'Promovarea și sprijinirea',
      description: 
        'integrării programelor de sănătate a inimii în cadrele de sănătate publică existente pentru a asigura o îngrijire cuprinzătoare și coordonată.',
    },
    {
      name: 'Creșterea',
      description: 
        'gradului de conștientizare publică și guvernamentală cu privire la importanța sănătății inimii și la necesitatea unor politici și programe de sprijin.',
    },
    {
        name: 'Promovarea',
        description: 
           'parteneriatelor cu agenții guvernamentale, organizații neguvernamentale (ONG-uri) și organisme internaționale pentru a ne amplifica eforturile de advocacy și a împărtăși cele mai bune practici.',
    },
    {
        name: 'Susținem crearea și implementarea',
        description: 
           'unei strategii naționale de sănătate a inimii care prezintă obiective clare, ținte și planuri de acțiune pentru a reduce povara bolilor cardiovasculare.',
    },
    {
        name: 'Promovam',
        description: 
            'politici care sprijină măsurile preventive de asistență medicală, cum ar fi screening-urile de rutină pentru sănătatea inimii, programele de modificare a stilului de viață și campaniile de sănătate publică.',
    },
    {
        name: 'Susținem',
        description: 
            'includerea educației pentru sănătatea inimii în programele școlare pentru a insufla obiceiuri sănătoase de la o vârstă fragedă.',
    },
    {
        name: 'Asigurarea',
        description: 
            'accesul echitabil la îngrijire cardiacă de calitate pentru toți, indiferent de statutul socio-economic sau locația geografică.',
    },
    {
        name: 'Susținem',
        description: 
           'extinderea infrastructurii și a resurselor de asistență medicală în zonele insuficient deservite pentru a reduce disparitățile în rezultatele sănătății inimii.',
    },
    {
        name: 'Încurajarea',
        description: 
            'investițiilor guvernamentale în cercetarea cardiovasculară pentru a avansa înțelegerea bolilor de inimă și pentru a dezvolta tratamente inovatoare.',
    },
    {
        name: 'Sprijinirea',
        description: 
            'politicilor care facilitează studiile clinice și adoptarea de noi tehnologii în îngrijirea sănătății inimii.',
    },
    {
        name: 'Susținem',
        description: 
            'programele de sănătate la locul de muncă care promovează sănătatea inimii prin inițiative precum controale regulate de sănătate, programe de fitness și ateliere de gestionare a stresului.',
    },
    {
        name: 'Colaborarea',
        description: 
            'cu angajatorii și factorii de decizie pentru a crea medii favorabile pentru ca angajații să mențină un stil de viață sănătos pentru inimă.',
        icon: TagIcon,
    },
  ];


    return(
      <div>
      <section id='desprenoi'>
        <div className="sm:mx-10 lg:mx-30 grid items-center gap-x-16 px-4 sm:px-6 lg:grid-cols-2 lg:px-8">
        {/* Features Section */}
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Despre noi</h2>
          <p className="mt-4 text-lg text-gray-500">
          Ideea de a crea un ONG a început să prindă contur în mintea mea de mult timp,
          alimentată de interacțiunile zilnice cu pacienții. Am observat câtă nevoie de
          informare există, de orientare și sprijin atât medical, cât și emoțional.
          Experiențele personale, inclusiv cele prin care am trecut eu și familia mea, mi-au
          arătat cât de complicat poate fi să accesezi servicii medicale esențiale: găsirea
          unui medic specialist, obținerea unei programări, localizarea unei unități medicale
          publice pentru montarea unui stimulator cardiac, efectuarea unei ecografii
          cardiace sau recuperarea după un accident vascular cerebral – toate acestea pot
          deveni o provocare enormă.
          </p>
          <p className="mt-4 text-lg text-gray-500">
          Chiar eu, având cunoștințe în domeniu, am întâmpinat dificultăți semnificative în
          accesarea acestor servicii. Mă gândesc, totodată, la persoanele care nu au
          expunere la informatii medicale; acestora le este cu atât mai greu să navigheze
          prin acest labirint. În aceste circumstanțe, dorința noastră de a crea un ONG a
          devenit o necesitate urgentă. Alături de alti patru colegi inimoși am fondat
          Asociația Solidari pentru Sănătate.
          </p>
          <p className="mt-4 text-lg text-gray-500">
          Scopul nostru este să oferim sprijin celor care se confruntă cu probleme de
          sănătate a inimii, să le furnizăm informații esențiale și să-i ajutăm să acceseze
          serviciile medicale de care au nevoie. Fiecare contribuție contează! Alăturați-vă
          acestei misiuni și deveniți parte din soluție. Împreună putem construi o
          comunitate mai sănătoasă și mai bine informată.
          </p>
          <p className="mt-4 text-lg text-gray-500">
          Misiunea noastră nu se va opri doar la ajutorul pacienților și familiilor acestora.
          Ne dorim să avem un rol activ în procesul decizional al autorităților, să formulăm
          propuneri pentru îmbunătățirea accesului la servicii medicale, să dezvoltăm
          programe de screening și să desfășurăm activități de lobby și advocacy la nivel
          local, național și european. Credem cu tărie că, prin implicare și colaborare,
          putem contribui la crearea unui sistem de sănătate mai eficient și mai accesibil
          pentru toți.
          </p>
        </div>
        {/* Image Section */}
        <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
          <img
            alt="Doctors investigating heart."
            src="treatment.jpeg"
            className="rounded-lg bg-gray-100"
          />
        </div>
      </div>
      
      </section>
      
      <section id='misiuni'>
        <div className="mx-5 mt-16 sm:mt-20 lg:mt-24 px-6 pb-6">
            <div className="mx-auto max-w-2xl pb-20 lg:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Misiunile noastre</h2>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-5 lg:gap-y-16">
                {steps.map((feature) => (
                <div key={feature.name} className="relative pl-16 -z-10">
                    <dt className="inline font-semibold text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                        <ArrowTrendingUpIcon aria-hidden="true" className="h-6 w-6 text-white" />
                    </div>
                    {feature.name}
                    </dt>
                    <dd className="inline mt-2 text-base leading-7 text-gray-600"> {feature.description}</dd>
                </div>
                ))}
            </dl>
        </div>
      </section>
      </div>
    );
};

export default Aboutus;