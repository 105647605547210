import Header from '../components/Header';
import Footer from "../components/Footer";
import '../assets/style.css';

const diseases = [
    { name: '1. Boala coronariană (CAD):', text: "Cel mai frecvent tip de boală de inimă, CAD apare atunci când arterele coronare care furnizează sânge mușchiului inimii se îngustează sau se blochează din cauza acumulării de placă (ateroscleroză). Acest lucru poate duce la angină pectorală (durere în piept) sau atac de cord."},
    { name: '2. Atac de cord (infarct miocardic):', text: "Un atac de cord apare atunci când o arteră coronariană devine complet blocată, întrerupând fluxul de sânge către o parte a mușchiului inimii. Intervenția medicală promptă este crucială pentru a minimiza daunele și a salva vieți."},
    { name: '3. Insuficiență cardiacă:', text: "Cunoscută și sub numele de insuficiență cardiacă congestivă, această afecțiune apare atunci când mușchiul inimii este slăbit sau rigid, afectând capacitatea sa de a pompa sânge eficient în tot corpul."},
    { name: '4. Aritmii:', text: "Acestea sunt anomalii ale ritmului inimii, care pot face ca inima să bată prea repede (tahicardie), prea încet (bradicardie) sau neregulat. Unele aritmii sunt benigne, în timp ce altele pot pune viața în pericol."},
    { name: '5. Bolile de inimă valvulare:​', text: "Aceasta implică deteriorarea sau defectele uneia sau mai multor valve ale inimii, afectând fluxul de sânge prin camerele inimii."},    
]

const factors = [
    { name: 'Dietă nesănătoasă:', description: 'Aport ridicat de grăsimi saturate, grăsimi trans, sare și zahăr.'},
    { name: 'Inactivitate fizică:', description: 'Lipsa exercițiilor fizice regulate sau stilul de viață sedentar.'},
    { name: 'Fumatul:', description: 'Consumul de tutun crește riscul de boli de inimă.'},
    { name: 'Hipertensiune arterială:', description: 'Hipertensiunea arterială dăunează arterelor în timp.'},
    { name: 'Colesterol ridicat:', description: 'Nivelurile ridicate de colesterol LDL (colesterol rău) pot duce la acumularea plăcii în artere.'},
    { name: 'Diabet:', description: 'Crește riscul de complicații cardiovasculare.'},
    { name: 'Istoric familial:', description: 'Predispoziție genetică la boli de inimă.'},
    { name: 'Vârsta și sexul:', description: 'Riscul crește odată cu vârsta și este mai mare la bărbați si la femeile ajunse la menopauză.'}
]

const symptoms = [
    { name: 'Durere sau disconfort în piept (angină pectorală)'},
    { name: 'Dificultăți de respirație'},
    { name: 'Oboseală'},
    { name: 'Umflarea picioarelor, gleznelor sau abdomenului'},
    { name: 'Bătăi neregulate ale inimii'},
    { name: 'Amețeli'},
    { name: 'Greață'}
  ]

const tests = [
    { name: 'Electrocardiogramă (ECG sau EKG)', scope: 'Măsoară activitatea electrică a inimii.', procedure: 'Electrozii sunt plasați pe piele pentru a înregistra impulsurile electrice care călătoresc prin mușchiul inimii. Ajută la detectarea neregulilor în ritmul cardiac (aritmii) și a semnelor de atacuri de cord anterioare.'},
    { name: 'Ecocardiografie', scope: 'Folosește unde cu ultrasunete pentru a crea imagini detaliate ale structurii și funcției inimii.', procedure: 'Un traductor este plasat pe piept pentru a produce imagini în timp real ale camerelor, valvelor și fluxului sanguin ale inimii. Ajută la evaluarea funcției mușchiului cardiac, a funcției valvei și la detectarea anomaliilor, cum ar fi lichidul din jurul inimii (revărsat pericardic).'},
    { name: 'Scanare CT cardiacă (tomografie computerizată)', scope: 'Oferă imagini detaliate ale inimii și vaselor de sânge.', procedure: 'Folosește raze X și tehnologie computerizată pentru a crea imagini transversale (felii) ale inimii. Ajută la detectarea bolilor coronariene, a problemelor valvelor cardiace și a defectelor cardiace congenitale.'},
    { name: 'RMN cardiac (imagistică prin rezonanță magnetică)', scope: 'Produce imagini detaliate ale structurii și funcției inimii.', procedure: 'Folosește un câmp magnetic și unde radio pentru a crea imagini de înaltă rezoluție ale inimii. Oferă informații despre leziunile mușchiului cardiac, funcția valvei cardiace și fluxul sanguin.'},
    { name: 'Analize de sânge', scope: 'Măsoară nivelurile de colesterol, trigliceride și alte substanțe din sânge care pot indica factori de risc pentru boli de inimă.', procedure: 'O probă de sânge este prelevată și analizată într-un laborator. Ajută la evaluarea nivelurilor lipidice, a markerilor inflamației și a enzimelor cardiace (troponină) eliberate în timpul unui atac de cord.'},
    { name: 'Teste de stres', scope: 'Evaluează modul în care inima funcționează în timpul stresului fizic.', procedure: 'Tipuri: 1. Test de stres de efort: Monitorizează ritmul cardiac, tensiunea arterială și EKG în timp ce faceți exerciții pe o bandă de alergare sau pe o bicicletă staționară. 2. Test de stres nuclear: Implică injectarea unei cantități mici de substanță radioactivă pentru a evalua fluxul de sânge către mușchiul inimii în timpul stresului. 3. Ecocardiograma de stres: Combină exercițiile fizice sau stresul indus de medicamente cu ecocardiografia pentru a evalua funcția inimii și fluxul sanguin.'},
]

const treatments = [
    { name: 'Dietă și nutriție', indication1: 'Adoptați o dietă sănătoasă pentru inimă, bogată în fructe, legume, cereale integrale, proteine slabe și grăsimi sănătoase.', indication2: 'Reduceți aportul de grăsimi saturate, grăsimi trans, colesterol, sodiu și zaharuri adăugate.'},
    { name: 'Activitate fizică', indication1: 'Implicați-vă în activități fizice regulate, cum ar fi mersul pe jos, înotul sau ciclismul.', indication2: 'Încercați să faceți cel puțin 150 de minute de exerciții fizice de intensitate moderată pe săptămână.'},
    { name: 'Fumat', indication1: 'Renunțați la fumat și evitați expunerea la fumatul pasiv.', indication2: 'Căutați sprijin prin consiliere, medicamente sau programe de renunțare la fumat.'},
    { name: 'Managementul greutății', indication1: 'Mențineți o greutate sănătoasă printr-o dietă echilibrată și exerciții fizice regulate.', indication2: 'Urmăriți un indice de masă corporală (IMC) în intervalul recomandat.'},
    { name: 'Managementul stresului', indication1: 'Practicați tehnici de reducere a stresului, cum ar fi yoga, meditația sau exercițiile de respirație profundă.', indication2: 'Implicați-vă în activități care promovează relaxarea și bunăstarea mentală.'},
    { name: 'Antihipertensive', indication1: 'Utilizat pentru a scădea tensiunea arterială ridicată (hipertensiune) și pentru a reduce riscul de boli de inimă.', indication2: 'Tipurile comune includ inhibitorii ACE, beta-blocantele, blocantele canalelor de calciu și diureticele.'},
    { name: 'Statine', indication1: 'Ajută la scăderea nivelului de colesterol LDL (rău) și la reducerea riscului de atacuri de cord și accidente vasculare cerebrale.', indication2: ''},
    { name: 'Agenți antiplachetari', indication1: 'Previne formarea cheagurilor de sânge prin inhibarea agregării trombocitare.', indication2: ''},
    { name: 'Anticoagulante', indication1: 'Previne cheagurile de sânge și reduce riscul de accident vascular cerebral, în special la pacienții cu fibrilație atrială.', indication2: ''},
]

const BoliCardiovasculare = () => {
    return(
        <div className="min-h-screen flex flex-col">
            <Header/>
            <div className="flex-grow">
                <section id='tipuriDeBoliCardiace'>
                    <div className="sm:mx-10 lg:mx-30 pb-24 grid items-center gap-x-16 px-4 sm:px-6 lg:grid-cols-2 lg:px-16">
                    {/* Diseases Section */}
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Tipuri de boli cardiace</h2>
                            {diseases.map((item) => (
                                <div>
                                    <p className="mt-6 text-lg text-gray-900 font-bold">
                                        {item.name}
                                    </p>
                                    <p className="text-lg text-gray-500"> 
                                        {item.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    {/* Image Section */}
                        <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
                            <img
                                alt="Heart shape red."
                                src="heart.png"
                                className="rounded-lg bg-gray-100"
                            />
                        </div>
                    </div>
                </section>
                <section id='factoriDeRisc'>
                    <div className="sm:mx-10 lg:mx-30 pt-24 pb-24 grid items-center gap-x-16 px-4 sm:px-6 lg:grid-cols-2 lg:px-16">
                    {/* Image Section */}
                        <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8 h-full">
                            <img
                                alt="Heart diseases factors."
                                src="factors.jpeg"
                                className="rounded-lg min-w-full min-h-full"
                            />
                        </div>
                    {/* Diseases Section */}
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Factori de risc</h2>
                            <p className="text-lg text-gray-500">Mai mulți factori cresc riscul de a dezvolta boli de inimă</p>
                            {factors.map((item) => (
                                <div>
                                    <p className="mt-6 text-lg text-gray-900 font-bold">
                                        {item.name}
                                    </p>
                                    <p className="text-lg text-gray-500"> 
                                        {item.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id='simptome'>
                    {/* Symptoms section */}
                    <div className="bg-white py-24 sm:py-32">
                        <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Simptome
                            </h2>
                            <p className="text-center text-lg text-gray-500 mt-6">
                                Simptomele bolilor de inimă variază în funcție de tip și severitate, dar pot include:
                            </p>
                            <div className="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-8 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-7">
                            {symptoms.map((item) => (
                                <div className="flex items-center justify-center lg:flex-row flex-row text-center gap-x-6 rounded-lg p-4 text-lg leading-6 hover:bg-gray-50 border-2 border-gray-50 h-52 w-44">
                                    <a href={item.href} className="block font-semibold text-gray-900">
                                        {item.name}
                                    </a>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </section>
                <section id='diagnosticare'>
                    {/*Diagnostics section */}
                    <div className="sm:mx-10 lg:mx-30 pt-24 pb-24 grid items-center gap-x-16 px-4 sm:px-6 lg:grid-cols-2 lg:px-16">
                    {/* Left side */}
                        <div>
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Diagnosticare</h2>
                            <p className="text-lg mt-6 text-gray-500">
                            Diagnosticul precis al bolilor de inimă implică adesea o varietate de teste și
                            proceduri de diagnostic pentru a evalua funcția inimii, a identifica anomaliile și a
                            determina planurile de tratament adecvate. Aceste teste îi ajută pe furnizorii de
                            servicii medicale să evalueze structura și funcția inimii, să evalueze factorii de risc
                            și să monitorizeze progresia bolii. Iată câteva teste de diagnostic utilizate în mod
                            obișnuit pentru bolile de inimă <span aria-hidden="true">→</span>
                            </p>
                            <h2 className="text-3xl mt-60 font-bold tracking-tight text-gray-900 sm:text-4xl">Concluzie</h2>
                            <p className="text-lg mt-6 text-gray-500">
                            Testele de diagnostic pentru bolile de inimă joacă un rol crucial în diagnosticarea
                            precisă a afecțiunilor, ghidarea deciziilor de tratament și monitorizarea sănătății
                            inimii în timp. În funcție de simptome, factori de risc și afecțiuni suspectate,
                            furnizorii de asistență medicală pot recomanda unul sau mai multe dintre aceste
                            teste pentru a evalua funcția inimii și a formula planuri de tratament personalizate.
                            Detectarea și intervenția timpurie sunt esențiale în gestionarea eficientă a bolilor de
                            inimă și în promovarea sănătății cardiovasculare.
                            </p>
                        </div>
                    {/* Right side */}
                        <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:gap-6 lg:gap-8">
                            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl lg:px-6 pb-6">
                                <div className="mx-auto max-w-2xl pb-20 lg:text-center">
                                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Tipuri de teste de diagnostic</h2>
                                </div>
                                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-1 lg:gap-y-8">
                                    {tests.map((item) => (
                                    <div key={item.name} className="lg:pl-16">
                                        <dt className="inline font-semibold text-gray-900">
                                        {item.name}
                                        </dt>
                                        <dd className="mt-2 text-base leading-7 text-gray-600">{item.scope}</dd>
                                        <dd className="mt-2 text-base leading-7 text-gray-600">{item.procedure}</dd>
                                    </div>
                                    ))}
                                </dl>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='tratamente'>
                    <div>
                        <div className="sm:mx-10 lg:mx-30 pt-24 pb-24 items-center gap-x-16 px-4 sm:px-6 lg:px-2">
                        {/* Diseases Section */}
                            <div>
                                <h2 className="text-3xl font-bold text-center tracking-tight text-gray-900 sm:text-4xl">Tratamente și proceduri pentru bolile de inimă</h2>
                                <p className="text-lg mt-6 lg:text-center text-gray-500"> 
                                    Tratarea bolilor de inimă implică o combinație de modificări ale stilului de viață,
                                    medicamente și diverse proceduri medicale. Scopul este de a gestiona simptomele,
                                    de a îmbunătăți calitatea vieții și de a reduce riscul de evenimente cardiovasculare
                                    ulterioare. Iată o prezentare generală a tratamentelor și procedurilor comune
                                    utilizate pentru a aborda bolile de inimă:
                                </p>
                                <div className='grid items-center grid-cols-1 lg:grid-cols-3'>
                                    {treatments.map((item) => (
                                        <div className='mt-6 pt-5 pb-5 m-5 h-auto  bg-gray-50 rounded-lg hover:bg-white transition ease-in-out duration-300'>
                                            <p className="text-lg text-gray-900 font-bold grid-cols-1 text-center">
                                                {item.name}
                                            </p>
                                            <div className='mt-6 grid lg:items-center grid-cols-1 lg:grid-cols-2 gap-6 pl-5 pr-5'>
                                                <p className={`text-lg text-center p-5 h-56 flex items-center mb-5 text-gray-500 rounded-lg bg-white hover:bg-gray-50 transition ease-in-out duration-300 ${!item.indication2 ? 'col-span-2' : ''}`}> 
                                                    {item.indication1}
                                                </p>
                                                <p className={`text-lg text-center p-5 h-56 flex items-center mb-5 text-gray-500 rounded-lg bg-white hover:bg-gray-50 transition ease-in-out duration-300 ${!item.indication2 ? 'hidden' : ''}`}> 
                                                    {item.indication2}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
  };
  
  export default BoliCardiovasculare;