import { useState } from "react";

const presa = [
    { title: 'Am insuficiență cardiacă, plec in vacanta', 
        publicatie1: 'Stirile Media', 
        src1: 'https://stirilemedia.ro/actualitate/ce-spun-medicii-specialisti-despre-gestionarea-insuficientei-cardiace-in-lunile-de-vara/',
        publicatie2: 'Romania Pozitiva', 
        src2: 'https://www.romaniapozitiva.ro/romania-internationala/ce-spun-medicii-specialisti-despre-gestionarea-insuficientei-cardiace-in-lunile-de-vara/',
        publicatie3: 'Eco Politic', 
        src3: 'https://ecopolitic.ro/ce-spun-medicii-specialisti-despre-gestionarea-insuficientei-cardiace-in-lunile-de-vara/',
        publicatie4: 'Special Arad', 
        src4: 'https://specialarad.ro/insuficienta-cardiaca-in-lunile-de-vara/',
        image: 'stock_1.jpg'},
]


const Presa = () => {
  return (
    <div className="mt-10 mb-10 flex justify-center items-center flex-col lg:flex-row lg:gap-10 gap-2">
        {presa.map((image, index) => (
            <div className="">
                <img
                    key={index}
                    className="rounded-2xl lg:w-80 lg:h-64 w-64 h-60 object-fill mx-auto"
                    src={image.image}
                    alt={`comunicat de presa solidari pentru sanatate ${index + 1}`}
                />
                <h2 className="text-center font-semibold">{image.title}</h2>
                <a href={image.src1} target="_blank">
                <h3 className="text-center font-semibold text-gray-500">{image.publicatie1}</h3>
                </a>
                <a href={image.src2} target="_blank">
                <h3 className="text-center font-semibold text-gray-500">{image.publicatie2}</h3>
                </a>
                <a href={image.src3} target="_blank">
                <h3 className="text-center font-semibold text-gray-500">{image.publicatie3}</h3>
                </a>
                <a href={image.src4} target="_blank">
                <h3 className="text-center font-semibold text-gray-500">{image.publicatie4}</h3>
                </a>
            </div>
        ))}
    </div>
  );
};

export default Presa;