import { useState } from "react";

const Presa = () => {
  return (
    <div className="mt-10 mb-10 flex justify-center items-center flex-col lg:flex-row lg:gap-10 gap-2">
        <iframe
        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FSolidari.cl%2Fvideos%2F473323798754403%2F&show_text=true&width=560&t=0"
        width="560"
        height="429"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
        <iframe
        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FSolidari.cl%2Fvideos%2F1010416663897092%2F&show_text=true&width=560&t=0"
        width="560"
        height="429"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
    </div>
  );
};

export default Presa;