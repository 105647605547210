import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './index.css';
import Aboutus from './pages/Aboutus.js';
import BoliCardiovasculare from './pages/BoliCardiovasculare.js';
import BoliCardiovascularePrevenire from './pages/BoliCardiovascularePrevenire.js';
import InformatiiDeUrgenta from './pages/InformatiiDeUrgenta.js';
import Doneaza from './pages/Doneaza.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/despre-noi" element={<Aboutus/>} />
        <Route path="/bolile-cardiovasculare" element={<BoliCardiovasculare/>} />
        <Route path="/bolile-cardiovasculare-prevenire-si-ingrijire" element={<BoliCardiovascularePrevenire/>} />
        <Route path="/informatii-de-urgenta" element={<InformatiiDeUrgenta/>} />
        <Route path="/doneaza" element={<Doneaza/>} />
      </Routes>
    </Router>
  );
}

export default App; 